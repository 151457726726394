<template>
  <div class="box-container fontForAll px-4">
    <div>
      <!-- phieuchidinh -->
      <div
        style="width: 1000px"
        class="row text-black fontForAll mt-4 justify-center mx-auto"
        id="element-to-print"
      >
        <div class="w-full">
          <div class="row mb-3">
            <div class="col-3 d-flex">
              <div class="inline-block align-top uppercase">
                <div class="flex flex-col" style="width: 300px">
                  <!-- <p class="mb-0 fs-17 font-bold">BỘ Y TẾ</p> -->
                  <p class="mb-0 fs-17 font-bold">
                    {{
                      indication_order.clinic &&
                      indication_order.clinic.org_name
                    }}
                  </p>
                  <p class="mb-0 fs-17 font-bold">
                    {{
                      indication_order.clinic && indication_order.clinic.name
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 text-center">
              <p class="fs-17 font-bold mb-0">
                CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
              </p>
              <p class="fs-17 font-bold mb-0">Độc lập - Tự do - Hạnh phúc</p>
              <p class="fs-17 font-bold mb-0">------------------------------</p>
            </div>
            <!-- <div class="col-3 d-flex justify-content-end text-right">
                <div>
                  <div class="text-left mr-12">
                    <p
                      class="mb-0 fs-18"
                    >Số hồ sơ: {{ indication_order && indication_order.medical_record && indication_order.medical_record.medical_record_code }}</p>
                    <p class="mb-0 fs-18 w-full whitespace-pre">PID: {{ person && person.code }}</p>
                  </div>
                </div>
            </div>-->
          </div>
          <div class="col-12 temp-name">
            <p class="mb-2 text-center text-uppercase font-bold">
              {{ indication_order && indication_order.name }}
            </p>
          </div>

          <!-- // -->
          <div class="row mb-1">
            <div class="col-8">
              <div class="flex">
                <p class="fs-13 mb-0 mr-1">Họ tên:&nbsp;</p>
                <p class="fs-13 mb-0 font-bold">
                  {{ patientInfo && patientInfo.name }}
                </p>
              </div>
            </div>
            <div class="col-4">
              <div class="flex">
                <p class="fs-13 mr-2 mb-0">
                  Tuổi:
                  {{ patientInfo && patientInfo.age ? patientInfo.age : "" }}
                </p>
                <p class="fs-13 mb-0">
                  Giới tính:
                  {{ genderFormat(patientInfo && patientInfo.gender) }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex mb-1">
            <p class="fs-13 mb-0">Địa chỉ:&nbsp;</p>
            <p class="fs-13 mb-0">{{ patientInfo && patientInfo.address }}</p>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="flex">
                <p class="fs-13 mb-0">Số thẻ BHYT:&nbsp;</p>
                <p class="fs-13 mb-0">
                  {{ patientInfo && patientInfo.insurance_number }}
                </p>
              </div>
            </div>
            <div class="col-4">
              <p class="fs-13 mb-0">
                Ngày hết hạn:
                {{ formatDate(patientInfo && patientInfo.insurance_expire) }}
              </p>
            </div>
          </div>
          <div class="flex mt-1">
            <p class="fs-13 mb-0">Chẩn đoán:&nbsp;</p>
            <!-- <p
              v-if="patientVisit &&patientVisit.consultation &&patientVisit.consultation.diagnose"
              class="fs-13 mb-0 content-size"
              v-html="patientVisit &&patientVisit.consultation &&patientVisit.consultation.diagnose"
            ></p>-->
          </div>
          <div>
            <!-- <p class="fs-13 mb-3">Bệnh kèm theo:</p> -->
            <!-- //table -->
            <div>
              <table class="table-auto border-w w-full mt-4">
                <thead>
                  <tr class="text-center">
                    <th class="fs-13 border-w">STT</th>
                    <th class="fs-13 border-w">TÊN CHỈ ĐỊNH</th>
                    <th class="fs-13 border-w">ĐƠN VỊ THỰC HIỆN</th>
                  </tr>
                </thead>
                <tbody v-if="indication_order">
                  <!-- <tr>
                    <td colspan="2" class="border-w fs-13">
                      <span class="ml-1 italic font-bold font-print">{{ group.name }}</span>
                    </td>
                    <td></td>
                  </tr>-->
                  <tr
                    v-for="(item, index) in indication_order.indication"
                    :key="item.id"
                  >
                    <td class="fs-13 border-w text-center font-print">
                      {{ index + 1 }}
                    </td>
                    <td class="fs-13 border-w margin-12">
                      <span class="ml-1 font-print">{{ item.name }}</span>
                    </td>
                    <td class="fs-13 border-w">
                      <span class="ml-1 font-print">
                        {{
                          indication_order.service_provider &&
                          indication_order.service_provider.name
                        }}
                      </span>

                      <!-- <span class="ml-1 font-print">{{ getDataDemo(item.target_id) }}</span> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- // -->
          <div class="flex justify-between">
            <div>
              <!-- <p class="italic fs-13 mt-1 font-bold">Hướng dẫn: </p> -->
            </div>
            <div class="text-center mt-3">
              <p class="mb-0 fs-13">{{ getHMDMYVN(date) }}</p>
              <p class="mb-5 fs-13 font-bold">BÁC SĨ ĐIỀU TRỊ</p>
              <p class="fs-13">{{ treat_doctor && treat_doctor.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- chidinh -->
    </div>
    <div class="d-print-none">
      <div class="d-flex justify-content-end mt-4">
        <div>
          <el-button @click="back">Quay lại</el-button>
          <el-button type="primary" class="btn bg-pri text-white" @click="print"
            >In Phiếu</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import JsBarcode from 'jsbarcode'
import moment from "moment";
import "element-ui/lib/theme-chalk/index.css";
import { Button } from "element-ui";
import store from "@/store";
import { PRODUCT_TYPES } from "@/utils/constant";

export default {
  name: "PrintIndicatorForm",
  components: {
    "el-button": Button,
  },
  props: {
    // indication_order: {}
  },
  data() {
    return {
      patientVisit: {},
      indication_order: {},
      patientInfo: {},
      date: new Date(),
      medical_record: null,
      treat_doctor: null,
      form: 1,
    };
  },
  mounted() {
    this.getIndicationOderDetail(this.$route.params.id);
  },
  methods: {
    formatDate(date) {
      if (!date) return;
      return window.moment(date).format("DD-MM-YYYY");
    },
    getDataDemo(id) {
      return id ? this.demo[id - 1].name : "";
    },
    genderFormat(gender) {
      if (gender === 1) {
        return "Nam";
      }

      if (gender === 2) {
        return "Nữ";
      }

      return "Other";
    },
    getHMDMYVN(date_time) {
      let dt = window.moment(date_time);
      let hm = dt.clone().format("HH:mm");
      let d = dt.clone().format("DD");
      let m = dt.clone().format("MM");
      let y = dt.clone().format("YYYY");
      return hm + ", Ngày " + d + " tháng " + m + " năm " + y;
    },
    async getIndicationOderDetail(id) {
      const res = await store
        .dispatch("orders/fetchOrdersById", {
          id: id,
        })
        .then((response) => {
          this.indication_order = response?.result?.data?.order_items?.find(
            (elm) => (elm.id = this.$route.query.order_id)
          ).indication_order;
          this.patientInfo = this.indication_order?.person;
        })
        .catch((e) => {
          console.log(e);
        });
      return res;
    },
    print() {
      document.title = `Phieu chi dinh_${moment().format("DDMMYYYY")}.pdf`;
      window.print();
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.box-container {
  color: black;
  margin: auto;
  ::v-deep * {
    font-family: "Times New Roman", "Times", "Tinos", serif !important;
  }
}

::v-deep {
  .el-checkbox {
    display: block;
  }
  .margin-12 {
    margin-left: 20px;
  }

  .fontForAll * {
    font-family: "Times New Roman", "Tinos", Times, serif !important;
    font-size: 14px;
  }

  .height-48 {
    height: 48px;
  }
  .border-b-space {
    border-bottom: 1px solid black;
  }
  .border-box {
    border: 1px solid black;
  }

  .temp-name p {
    font-size: 18px;
  }
  // @media screen {
  //   .h-item-scroll {
  //     overflow-y: auto;
  //     height: 80vh;
  //   }
  // }
  .border-w {
    border: 1px solid black;
  }
  p,
  th {
    font-family: "Tinos", serif;
  }
  .font-print {
    font-family: "Tinos", serif;
  }
}
.border-w {
  border: 1px solid black;
}

@media print {
  @page {
    size: portrait;
    margin: 0;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  html,
  body {
    .fontForAll * {
      font-family: "Times New Roman", "Tinos", Times, serif !important;
      font-size: 18px !important;
    }
    overflow: hidden;
  }

  p.bodyText {
    display: none;
  }
}
</style>
